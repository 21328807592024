<template>
  <div id="bidDetail" class="base_min_width">
    <div class="bidDetailCenter base_width">
      <div class="header flex">
        <div class="top flex_col">
          <h3 class="title">
            {{ datalist.title }}
          </h3>
          <div style="margin-top: 10px">
            <span v-show="datalist.region_name" class="tagsItem reg">{{
              datalist.region_name
            }}</span>
            <span v-show="datalist.sort" class="tagsItem sort">{{
              datalist.sort
            }}</span>
            <span v-show="datalist.class_name" class="tagsItem class">{{
              datalist.class_name
            }}</span>
          </div>
          <div class="topBot flex">
            <div class="topAmount">
              <span v-if="datalist.class_code === '1001'">预算金额</span>
              <span v-else>中标/成交金额</span>
              <span class="topAmount_num"
                >{{ amountFlect(datalist.main_info) }}万</span
              >
            </div>
            <div
              v-if="datalist.main_info && datalist.release_date"
              style="margin-right: 32px; font-size: 14px"
            >
              发布时间：{{ datalist.release_date }}
            </div>
            <div
              class="topAmount"
              v-if="datalist.class_code === '1002'"
              style="margin-right: 0; font-size: 14px"
            >
              <template v-if="datalist.main_info.supplier">
                <span>中标/成交供应商：</span>
                <span style="color: #333">{{
                  supplierChange(datalist.main_info)
                }}</span>
                <span
                  style="
                    color: #3c89ff;
                    position: relative;
                    padding: 0 10px 10px;
                  "
                  v-if="showMore"
                  @mouseenter="showMoreTips = true"
                  @mouseleave="showMoreTips = false"
                  >更多
                  <bidTips
                    :supplierList="datalist.main_info.supplier_list"
                    v-if="showMoreTips"
                  />
                </span>
              </template>
            </div>
            <div v-if="datalist.main_info && datalist.main_info.expire_date">
              投标截止时间：{{ datalist.main_info.expire_date }}
            </div>
          </div>
        </div>
        <div class="topRight flex_col">
          <div
            class="btnBid monitor"
            @mouseenter.prevent="showQr = true"
            @mouseleave.prevent="showQr = false"
          >
            项目监控
          </div>
          <div class="qr flex_col queue-bottom" v-if="showQr">
            <img :src="imgUrl" alt="" />
            <span>打开微信扫一扫，项目监控更方便</span>
          </div>
          <!-- <div class="btnBid keep pointer">收藏</div> -->
        </div>
      </div>
      <div class="content flex base_width">
        <div class="ct">
          <!-- 关键信息 -->
          <div class="iInfo">
            <h3 class="ctTitle">关键信息</h3>
            <div v-if="datalist.class_code === '1001'" class="iInfo_ct flex">
              <span
                v-if="datalist.main_info && datalist.main_info.purchaser"
                :class="{
                  iInfo_item: true,
                  longLine: datalist.main_info.purchaser.length > 40
                }"
              >
                <i>采购单位：</i>
                <span>{{ datalist.main_info.purchaser }}</span>
              </span>
              <span
                v-if="datalist.main_info && datalist.main_info.agency"
                :class="{
                  iInfo_item: true,
                  longLine: datalist.main_info.agency.length > 40
                }"
              >
                <i>代理机构：</i>
                <span>{{ datalist.main_info.agency }}</span>
              </span>
              <span
                v-if="datalist.main_info && datalist.main_info.contact_person"
                :class="{
                  iInfo_item: true,
                  longLine: datalist.main_info.contact_person.length > 40
                }"
              >
                <i>代理联系人：</i>
                <span>{{ datalist.main_info.contact_person }}</span>
              </span>
              <span
                v-if="datalist.main_info && datalist.main_info.contact_phone"
                :class="{
                  iInfo_item: true,
                  longLine: datalist.main_info.contact_phone.length > 40
                }"
              >
                <i>联系电话：</i>
                <span>{{ datalist.main_info.contact_phone }}</span>
              </span>
              <span
                v-if="datalist.main_info && datalist.main_info.project_code"
                :class="{
                  iInfo_item: true,
                  longLine: datalist.main_info.project_code.length > 40
                }"
              >
                <i>项目编号：</i>
                <span>{{ datalist.main_info.project_code }}</span>
              </span>
              <span
                v-if="datalist.main_info && datalist.main_info.duration"
                :class="{
                  iInfo_item: true,
                  longLine: datalist.main_info.duration.length > 40
                }"
              >
                <i>工期：</i>
                <span>{{ datalist.main_info.duration }}</span>
              </span>
              <span
                v-if="datalist.main_info && datalist.main_info.tender_type"
                :class="{
                  iInfo_item: true,
                  longLine: datalist.main_info.tender_type.length > 40
                }"
              >
                <i>招标方式：</i>
                <span>{{ datalist.main_info.tender_type }}</span>
              </span>
              <span
                v-if="datalist.main_info && datalist.main_info.project_type"
                :class="{
                  iInfo_item: true,
                  longLine: datalist.main_info.project_type.length > 40
                }"
              >
                <i>工程类型：</i>
                <span>{{ datalist.main_info.project_type }}</span>
              </span>
            </div>
            <div v-else>
              <div class="iInfo_ct flex">
                <span
                  v-if="datalist.main_info && datalist.main_info.address"
                  :class="{
                    iInfo_item: true,
                    longLine: datalist.main_info.address.length > 40
                  }"
                >
                  <i>中标单位地址：</i>
                  <span>{{ datalist.main_info.address }}</span>
                </span>
                <span
                  v-if="datalist.main_info && datalist.main_info.contact_person"
                  :class="{
                    iInfo_item: true,
                    longLine: datalist.main_info.contact_person.length > 40
                  }"
                >
                  <i>中标联系人：</i>
                  <span>{{ datalist.main_info.contact_person }}</span>
                </span>
                <span
                  v-if="datalist.main_info && datalist.main_info.supplier_phone"
                  :class="{
                    iInfo_item: true,
                    longLine: datalist.main_info.supplier_phone.length > 40
                  }"
                >
                  <i>中标方电话：</i>
                  <span>{{ datalist.main_info.supplier_phone }}</span>
                </span>
                <span
                  v-if="datalist.main_info && datalist.main_info.purchaser"
                  :class="{
                    iInfo_item: true,
                    longLine: datalist.main_info.purchaser.length > 40
                  }"
                >
                  <i>采购单位：</i>
                  <span>{{ datalist.main_info.purchaser }}</span>
                </span>
                <span
                  v-if="datalist.main_info && datalist.main_info.project_code"
                  :class="{
                    iInfo_item: true,
                    longLine: datalist.main_info.project_code.length > 40
                  }"
                >
                  <i>项目编号：</i>
                  <span>{{ datalist.main_info.project_code }}</span>
                </span>
                <span
                  v-if="datalist.main_info && datalist.main_info.duration"
                  :class="{
                    iInfo_item: true,
                    longLine: datalist.main_info.duration.length > 40
                  }"
                >
                  <i>工期：</i>
                  <span>{{ datalist.main_info.duration }}</span>
                </span>
                <span
                  v-if="datalist.main_info && datalist.main_info.tender_type"
                  :class="{
                    iInfo_item: true,
                    longLine: datalist.main_info.tender_type.length > 40
                  }"
                >
                  <i>招标方式：</i>
                  <span>{{ datalist.main_info.tender_type }}</span>
                </span>
                <span
                  v-if="datalist.main_info && datalist.main_info.project_type"
                  :class="{
                    iInfo_item: true,
                    longLine: datalist.main_info.project_type.length > 40
                  }"
                >
                  <i>工程类型：</i>
                  <span>{{ datalist.main_info.project_type }}</span>
                </span>
              </div>
            </div>
          </div>
          <div>
            <!-- <h3 class="ctTitle">公告详情</h3> -->
            <div class="pB-40">
              <div v-if="datalist.type === 1">
                <img
                  class="web_img"
                  :src="datalist.img_url"
                  style="width: 100%"
                />
              </div>
              <div v-else-if="datalist.type === 2">
                <ul v-for="(item, index) in allList" :key="index">
                  <li class="section">
                    <div class="section-head">
                      <div class="left_div">
                        <p class="title ctTitle">{{ item.title }}</p>
                      </div>
                    </div>
                    <div v-if="item.status" class="flex listContent">
                      <ul
                        v-for="(item2, index2) in item.list"
                        :key="index2"
                        :class="{ longLine: item2.kvalue.length > 40 }"
                      >
                        <li class="section-row flex">
                          <span class="iInfo2_item">
                            <i>{{ item2.kname }}：</i>
                            <span>{{ item2.kvalue }}</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-if="datalist.annexes_list && datalist.annexes_list.length > 0">
            <h3 class="ctTitle mB-30">附件</h3>
            <div class="pB-60">
              <down-item
                :down-list="datalist.annexes_list"
                @downItem="downItem(datalist.annexes_list)"
              />
            </div>
          </div>
          <!-- 原文链接 -->
          <div>
            <h3 class="ctTitle mB-30">原文链接</h3>
            <a
              :href="datalist.source_url"
              class="link-a"
              target="_blank"
              rel="nofollow"
              >阅读原文</a
            >
          </div>
        </div>
        <div class="ctRight">
          <div class="qr flex_col">
            <img :src="imgUrl" alt="" />
            <!-- <img src="@/assets/images/bid/bid.jpg" alt="" /> -->
            <span>打开微信扫一扫，项目监控更方便</span>
          </div>
          <div class="qr2 flex">
            <img src="@/assets/images/bidList/bidDetail.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <commonForm ref="commonForm" />
  </div>
</template>

<script>
import DownItem from '@/common/BaseDownload.vue'
import { bidDetail, bidZjjContent } from '@/api/bidList'
import { bidQrCode } from '@/api/qr'
import bidTips from './BidDetailTips'

export default {
  components: {
    DownItem,
    bidTips
  },
  data() {
    return {
      datalist: {
        main_info: {}
      },
      allList: [],
      showQr: false,
      imgUrl: require('@/assets/images/bid/bid.jpg'),
      showMore: false,
      showMoreTips: false
    }
  },
  computed: {
    amountFlect() {
      return value => {
        if (
          value &&
          value.amount !== '' &&
          value.amount !== '0' &&
          value.amount !== undefined
        ) {
          console.log(value.amount)
          return value.amount
        } else {
          return '0.00'
        }
      }
    }
  },
  async created() {
    const { uid = '' } = this.$route.query
    const { data } = await bidDetail({ uid })
    this.datalist = data

    // 修改标题
    document.title = data.title + '-慧点招标'

    if (this.datalist.type === 2) {
      const { data } = await bidZjjContent({ uid })
      // console.log(data)
      const zbgs_list = data.zbgs_list || []
      if (zbgs_list.length > 0) {
        this.allList.push({
          title: '中标公示',
          list: zbgs_list,
          status: true
        })
      }
      const zbrxx_list = data.zbrxx_list || []
      if (zbrxx_list.length > 0) {
        this.allList.push({
          title: '中标人信息',
          status: true,
          list: zbrxx_list
        })
      }
      const zbr_list = data.zbr_list || []
      if (zbr_list.length > 0) {
        this.allList.push({
          title: '招标人及招标代理',
          status: true,
          list: zbr_list
        })
      }
      const zbgk_list = data.zbgk_list || []
      if (zbgk_list.length > 0) {
        this.allList.push({
          title: '招标概况',
          status: true,
          list: zbgk_list
        })
      }
      const bdxx_list = data.bdxx_list || []
      if (bdxx_list.length > 0) {
        this.allList.push({
          title: '标段信息',
          status: true,
          list: bdxx_list
        })
      }
    }
    bidQrCode({
      url: 'pages/webView2/webView2',
      scene: `pro_7_${this.$route.query.uid}_123456`
      // scene: 'pro_7_657998194_123456'
    }).then(res => {
      if (res.status === '1') {
        this.imgUrl = 'data:image/jpeg;base64,' + res.data
      }
    })
  },
  methods: {
    downItem() {
      const policyTitle = this.datalist.title
      const uid = this.$route.query.uid
      this.$refs.commonForm.show({
        moreParams: { policyTitle, uid: uid.toString(), fromBid: true }
      })
    },
    toRouter(url) {
      this.$router.push({ path: url })
    },
    supplierChange(main_info) {
      if (main_info.supplier_list.length > 0) {
        this.showMore = true
        return main_info.supplier.split('.')[1]
      } else {
        return main_info.supplier
      }
    }
  }
}
</script>
<style lang='scss' scoped>
#bidDetail {
  background: #f9f9f9;
  .bidDetailCenter {
    margin: auto;
  }
  width: 100%;
  background-color: #f9f9f9;
  padding: 137px 0 36px;
  .detail {
    margin: auto;
    width: 845px;
  }
  $shadowColor: rgba(242, 242, 242, 0.5);

  @mixin bgIcon($url) {
    background: url('~assets/images/' + $url) no-repeat center center/cover;
    width: 11px;
    height: 11px;
    margin-right: 7px;
    display: inline-block;
    vertical-align: middle;
  }
  .clockIcon {
    // @include bgIcon("home/clock.png");
  }
  .downIcon {
    // @include bgIcon("detail/down.png");
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }
  .file-btn {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 32px;
    color: #3c89ff;
    opacity: 1;
    float: right;
    margin-top: 10px;
  }
  .cmBox {
    // width: 845px;
    // margin: 0 auto;
    box-shadow: 0px 8px 5px $shadowColor;
    opacity: 1;
    border-radius: 6px;
  }

  .mB- {
    &30 {
      margin-bottom: 30px;
    }
  }
  .pB- {
    &40 {
      padding-bottom: 40px;
    }
    &60 {
      padding-bottom: 60px;
    }
  }
  .link-a {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 15px;
    color: #3c89ff;
    opacity: 1;
  }
  .header {
    @extend .cmBox;
    justify-content: space-between;
    background: #ffffff;
    padding: 54px 62px 41px 38px;
    .top {
      // margin-right: 104px;
      .title {
        // width: 564px;
        max-width: 807px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 43px;
        color: #333333;
        opacity: 1;
      }
      .tagsItem {
        padding: 7px 10px;
        height: 30px;
        background: #f2f5f7;
        opacity: 1;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 15px;
        color: #849aae;
        opacity: 1;
        border-radius: 3px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
      .reg {
        background: rgba(243, 43, 46, 0.1);
        color: #f32b2e;
      }
      .sort {
        background: rgb(231, 248, 240);
        color: #15bb71;
      }
      .class {
        background: rgb(245, 245, 245);
        color: #cda17b;
      }
      .timeTag {
        margin-left: 7px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bbbbbb;
        opacity: 1;
      }
      .topBot {
        margin-top: 28px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        display: inline-block;
        & > div {
          display: inline-block;
        }
      }
      .topAmount {
        margin-right: 32px;
      }
      .topAmount_num {
        display: inline-block;
        margin-left: 5px;
        vertical-align: -3px;
        height: 47px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff5b60;
        opacity: 1;
      }
    }
    .topRight {
      position: relative;
      align-items: center;
      .qr {
        position: absolute;
        top: 50px;
        width: 321px;
        height: 261px;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px -3px 80px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        justify-content: center;
        img {
          // margin-top: 60px;
          width: 155px;
          height: 155px;
        }
        span {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #999999;
        }
      }
      .btnBid {
        width: 189px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 47px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
      .monitor {
        background: linear-gradient(135deg, #3c89ff 0%, #53abff 100%);
        box-shadow: -4px 3px 30px rgba(47, 135, 247, 0.3);
      }
      .keep {
        margin-top: 38px;
        border: 1px solid #3c89ff;
        border-radius: 28px;
        color: #3c89ff;
      }
    }
  }
  .content {
    @extend .cmBox;
    margin-top: 30px;
    .ct {
      background: #ffffff;
      width: 845px;
      padding: 51px 46px 50px 39px;
      .ctTitle {
        font-size: 16px;
        line-height: 1;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #555555;
        letter-spacing: 3px;
        opacity: 1;
      }
      .iInfo_ct {
        margin-top: 24px;
        // padding-bottom: 34px;
        flex-wrap: wrap;
      }
      .iInfo_item {
        display: flex;
        border: 1px solid #e4eef6;
        // width: 333px;
        width: 50%;
        margin-bottom: -1px;
        margin-right: -1px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 22px;
        color: rgba(96, 98, 102, 0.85);
        opacity: 1;
        & > i {
          font-style: normal;
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          width: 126px;
          // height: 49px;
          padding: 11px;
          background: #f0f7fc;
          // margin-right: 18px;
        }
        span {
          flex: 1;
          padding: 11px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
      .longLine {
        width: 100%;
      }
    }
    .ctRight {
      margin-left: 34px;
      flex: 1;
      .qr {
        width: 321px;
        height: 261px;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px -3px 80px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        img {
          margin-top: 37px;
          width: 155px;
          height: 155px;
        }
        span {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #999999;
        }
      }
      .qr2 {
        margin-top: 19px;
        height: 385px;
        width: 321px;
        background: #ffffff;
        box-shadow: 0px 8px 5px rgba(242, 242, 242, 0.5);
        border-radius: 6px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.pB-40 {
  .ctTitle {
    margin-top: 58px;
    margin-bottom: 26px;
    font-size: 16px;
  }
  .iInfo2_item {
    display: flex;
    border: 1px solid #e4eef6;
    // width: 333px;
    width: 100%;
    margin-bottom: -1px;
    margin-right: -1px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 22px;
    color: rgba(96, 98, 102, 0.85);
    opacity: 1;
    & > i {
      font-style: normal;
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      width: 126px;
      // height: 49px;
      padding: 11px;
      background: #f0f7fc;
      // margin-right: 18px;
    }
    span {
      flex: 1;
      padding: 11px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
  .longLine {
    width: 100%;
  }
}
.to-router {
  padding: 20px 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.listContent {
  flex-wrap: wrap;
  ul {
    width: 50%;
    li {
      height: 100%;
    }
  }
}
</style>
