
import { defineComponent, PropType } from 'vue'
interface IsupplierList {
  amount: string;
  supplier: string;
}
export default defineComponent({
  props: {
    supplierList: Array as PropType<IsupplierList[]>
  }
})
