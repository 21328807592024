<template>
  <ul class="bidTips">
    <li v-for="(item, index) in supplierList" :key="index">
      {{ item.supplier.split('.')[1] }}
    </li>
    <div class="shape"></div>
  </ul>
</template>
<script lang='ts'>
import { defineComponent, PropType } from 'vue'
interface IsupplierList {
  amount: string;
  supplier: string;
}
export default defineComponent({
  props: {
    supplierList: Array as PropType<IsupplierList[]>
  }
})
</script>
<style lang='scss' scoped>
.bidTips {
  white-space: nowrap;
  position: absolute;
  padding: 12px 7px 12px 19px;
  background: #565656;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
  right: 0;
  top: 30px;
  z-index: 1000;
  transform: translateX(50%);
  .shape{
    position: absolute;
    border-bottom: 9px solid #565656;
    border-right: 7.5px solid transparent;
    border-left: 7.5px solid transparent;
    top: -9px;
    right: 55%;
  }
}
</style>
