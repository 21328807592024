import request from '@/utils/request'
import { RequestData } from '@/types/index'

// 最新招/中标公示
export function latestBid(data?: RequestData) {
  return request({
    url: '/pla/tender/indexSearch ',
    method: 'post',
    data
  })
}
// 招标详情
export function bidDetail(data?: RequestData) {
  return request({
    url: '/pla/tender/bidDetail',
    method: 'post',
    data
  })
}

// 住建局招标数据
export function bidZjjContent(data?: RequestData) {
  return request({
    url: '/pla/policy/bidZjjContentTender',
    method: 'post',
    data
  })
}
